/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  aboutDescription: 'We seek partners, not simply clients. We are systems engineers. We are partners in the design and continuous development of complex, cross-platform, and robust systems.',
  whyAgileDescription: 'Technology is aging faster and faster. What worked well yesterday is outdated today and irrelevant tomorrow. Our approach: We build flexible foundations that we can adapt quickly and easily.',
  technologiesDescription: 'Systems engineering helps us meet the challenges of these factors. Every area of freshcells has specialists working in their fields – all of them with an interdisciplinary mindset.',
  careerDescription: 'Designing, developing, and maintaining e-commerce applications is our business. Cutting-edge technologies, customers who want more than average, and a vibrant team',
  contactDescription: 'Do you have any questions? About one of our client projects? Or a vacancy? Or are you looking for an e-commerce solution? Don\'t hesitate to contact us.',
  homeDescription: 'We create digital solutions with strategic business relevance. We plan, build, and grow digital solutions and services that help companies become leaders in their industry.',
  imprintDescription: 'Thank you for your interest in our website and company.',
  partnerDescription: 'We seek partners not clients, because your success is our goal. It is our strong belief that our long-term collaborations and partnerships are the basis for this.',
  privacyPolicyDescription: 'The protection of personal information collected, processed, and used in relation to your visit to our website is very important to us. Your data is protected within the framework of legal requirements.',
  privacyPolicyApplicantsDescription: 'The protection of personal information collected, processed, and used in relation to your visit to our website is very important to us. Your data is protected within the framework of legal requirements.',
  productsDescription: 'Manage content with our mightyC content player; satisfy demands for online travel portals using our TravelSandbox®; manage your website content using our freshMS',
  projectManagerDescription: 'We are not a hire-and-fire company. Among other vacancies, we are looking for a full-time Project Manager for Software Development to support our team in Düsseldorf.',
  seniorProjectManagerDescription: 'We are not a hire-and-fire company. Among other vacancies, we are looking for a full-time Senior Project Manager for Software Development to support our team in Düsseldorf.',
  seniorFullStackPHPDescription: 'We are not a hire-and-fire company. Among other vacancies, we are looking for a Senior Full Stack Developer PHP for Software Development to support our team in Düsseldorf.',
  technicalProjectManagerDescription: 'We are not a hire-and-fire company. Among other vacancies, we are looking for a full-time Technical Project Manager for Software Development to support our team in Düsseldorf.',
  softwareTesterDescription: 'We are not a hire-and-fire company. Among other vacancies, we are looking for a part-time Student Software Tester to support our team in Düsseldorf.',
  salesDirectorDescription: 'We are not a hire-and-fire company. Among other vacancies, we are looking for a part-time Student Software Tester to support our team in Düsseldorf.',
  devOpsDescription: 'We do not have a hire-and-fire mentality; we prefer to kick back with cold drinks after work. Positions we are seeking to fill include a DevOps Engineer(full-time software development) to support our team in Dusseldorf.',
  fullstackDevDescription: 'We do not have a hire-and-fire mentality; we prefer to kick back with cold drinks after work. Positions we are seeking to fill include a fullstack developer (full-time) to support our team in Dusseldorf.',
  AccountantDescription: 'We do not have a hire-and-fire mentality; we prefer to kick back with cold drinks after work. Positions we are seeking to fill include an accountant (part or full time) to support our team in Dusseldorf.',
  ReceptionistDescription: 'To strengthen our team in Düsseldorf, we are looking for support for reception & office management (m/f/d) in part-time 32 hrs.',
  FrontendDesignerDescription: 'We are not a hire-and-fire company. Among other vacancies, we are looking for a full-time Frontend Designer for Software Development to support our team in Düsseldorf.',
  softwareArchitectDescription: 'Among other vacancies, we are looking for a full-time Senior Software Architect for Software Development to support our team in Düsseldorf',
  seniorArchitectDescription: 'Among other vacancies, we are looking for a full-time Senior Software Architect for Software Development to support our team in Düsseldorf',
  seniorJavaDeveloperDescription: 'Among other vacancies, we are looking for a full-time Senior Java Developer for Software Development to support our team in Düsseldorf',
  referencesDescription: 'Some of the references from our 100+ clients from all kinds of industries, including Travel, Healthcare, Finance, Automotive, Construction & many more',
  servicesDescription: 'Are you starting out or are you already an established player in your market segment? We deliver and develop the system environment needed to increase your long-term success. Together with you, we digitize your individual processes.',
  appDevelopmentDescription: 'Native mobile application design is one of our core competencies. We develop with efficient frameworks. We design dedicated apps for popular operating systems like iOS and Android.',
  conceptStrategyDescription: ' We support you in the ongoing development and expansion of your projects. This is our understanding of a sustainable and long-term partnership. We follow up on new technologies and market trends to identify new areas of growth.',
  developmentDescription: 'Flexibility, stability, and scalability are indicators for the quality of digital systems. We involve the best, experienced developers to meet our standards of quality.',
  itConsultingDescription: 'Digital projects are always iterative processes. Sometimes it’s necessary to step back to go forward. We support you with collaborative consulting to get the best out of it.',
  projectManagementDescription: 'Your project success: Reaching the defined goal in time and budget. Our project managers oversee everything and act as contact partners for all project members.',
  systemEngineeringDescription: 'Systems should support the users. When tackling any type of systems engineering project, a ground-up approach makes all the difference. We maintain a common understanding and vision of the individual requirements.',
  systemIntegrationDescription: 'System landscapes generally grow at their own pace. Our systems engineering team provides you with integration security, analysis of functional models, and more.',
  toolsDescription: 'Developing native and cross-platform mobile apps. Handling complex functionalities in app user interfaces. Optimizing for best performance',
  uiUxDesignDescription: 'Websites need to be increasingly dynamic. They must be individual, easy to use, and mobile responsive. Our UX experts develop frontend concepts that keep pace and even stay ahead of the times.',
  travelsandboxDescription: 'A modular travel framework – TravelSandbox® is the result of many years of intensive travel portal experience. It is a powerful toolbox that provides sustainable solutions for your web presence.',
  mightycDescription: 'mightyC® is a content player that offers a collection of features, tools and APIs that manages your content. mightyC® aggregates your content to make it reusable, and for its distributes dynamic playback.',
  freshMsDescription: 'freshMS allows you to create and edit your website content in an intuitive way. This new form of editing includes direct connections to third-party systems like player-hub-systems',
  fusionDescription: 'Fusion is our answer to the market need of independence. Be flexible with all your product sources. With Fusion it doesn’t matter if you rely on Peakwork, Amadeus or anything else. You even can mix things up.',
  quickstartDescription: 'The Quickstart package is designed to enable customers to continue using the underlying TravelSandbox® in the future and to further develop their platform on this basis in a flexible, high-quality and stable manner.',
  ibeDescription: 'IBE is the abbreviation for "Internet Booking Engine". The abbreviation IBE is especially common in the tourism industry. There IBEs are used, in order to sell on-line package tours and travel components, like flights and overnight accomodation.',
  NewsRoomDescription: 'Get the latest news and press releases from freshcells',
  NewsOneDescription: 'Based on its TravelSandbox®, Düsseldorf, Germany, based freshcells systems engineering GmbH ensured a seamless transition in record time for resort operator Aldiana’s online packaging and booking processes.',
  NewsTwoDescription: 'Only one month after receiving the contract, Düsseldorf-based freshcells systems engineering GmbH goes live with the new booking process for HLX Touristik GmbH\'s Eurowings Holidays.',
  NewsMefaDescription: 'The special feature: all portals are controlled from a central management system with a specially integrated user administration.',
  NewsRadevormwaldDescription: 'Die Stadt Radevormwald plant die Anschaffung eines Dokumentenmanagement-Systems und beauftragt die System-Ingenieure der freshcells systems engineering GmbH zur technischen Evaluierung und Planung.',
  NewsUniversalDescription: 'Swiss tour operator Universal Flugreisen AG decides to completely renew its digital sales channels and relies on TravelSandbox® Quickstart from the Düsseldorf web software experts at freshcells systems engineering GmbH.',
  NewsITB2020Description: 'Düsseldorf, Februar 2020 - Der Softwareanbieter freshcells systems engineering GmbH aus Düsseldorf präsentiert auf der ITB 2020 (Halle 5.1, Stand 136) eine Einsteiger-IBE, die auf dem Framework TravelSandbox® aufsetzt: Quickstart.',
  NewsEtravelDescription: 'eTravel is the new Fischer Group travel portal for the Czech market, offering one of the most comprehensive ranges of offers available.',
  MetaDescription404: '404 Page not found.',
  ecommerceDescription: 'E-Commerce in perfection, use undreamt-of possibilities.',
  headlesscmsDescription: 'The future is headless. Experience the advantages of a modern CMS.',
  softwaredevelopmentDescription: 'Software development made in Germany. With an experienced service provider to a successful project.',
  softwaredevelopmentbDescription: 'A successful software project is not always easy. Find out what you have to pay attention to during the development.',
  uxdesignDescription: 'UX Design with years of experience. Inspire your customers with the best user guidance.',
  CaseStudyDescription: 'We plan, develop & extend digital products and services that help companies become pioneers in their industry. Our case studies testify to over 10 years of experience.',
  CaseStudyAldianaDescription: 'Aldiana offers high quality holidays under the motto "Holidays with friends", because Aldiana is not an institution, not a formula and not a place, but an attitude towards life.',
  CaseStudyArzDescription: 'ARZ Haan AG is a company with spezialized subsidiary companies, which offers digitalized billing-processes, data-analysis and -management for the health industry.',
  CaseStudyBedfinderDescription: 'Launching a new, online brand with new product was a major growth opportunity for Hotelplan - the largest tour operator in Switzerland – as the Internet is a global platform that breaks down international boundaries - but also a major challenge.',
  CaseStudyDerDescription: 'With its brands Kuoni, Helvetic Tours, and lastminute.ch, DER Touristik Suisse AG is the Swiss market leader. It now operates all of its booking websites with only a single enterprise booking platform using freshcells fusion and TravelSandbox® technology.',
  CaseStudyEtravelDescription: 'Innovative solution for Czech market needs. eTravel is one of the strongest tour operators in the Czech Republic.',
  CaseStudyHlxDescription: 'HLX Touristik GmbH powers holiday brands for Lufthansa, Swiss, Radio Energy, Condor and platforms for travelzoo, secret escapes, Urlaubs Guru and many more.',
  CaseStudyPlattenplanerDescription: 'Configure and calculate quickly and easily without registration from your kitchen top to the living room table, to the shower back panel.',
  CaseStudyWeekendDescription: 'Book short weekend trips quickly and easily. Compare offers as usual and select the best offer. Very simple on the web or mobile in the app for iOS or Android',
  CaseStudyYtravelDescription: 'Ytravel is the new way to share travel offers with your followers. This platform is a unique and innovative way to create ready to share travel packages.',
  CaseStudySmmDescription: 'Modernization and Individualization of a Tourism Platform for S-Markt und Mehrwert',
  hcmsIntegrationDescription: 'Portal solutions based on Headless CMS Basis will make you independent',
  strapiDescription: 'freshcells is solution and integration partner of Strapi',
  contentstackDescription: 'freshcells is a certified solution partner of Contentstack',
  NewsContentstackDescription: 'freshcells systems engineering becomes a certified integration partner for headless CMS provider Contentstack.',
  NewsSparkasseDescription: 'Die Reiseseiten von über 100 Sparkassen werden zukünftig von einem leistungsstarken Whitelabel-System des Technologiepartners freshcells systems engineering aus Düsseldorf bereitgestellt. Zum Einsatz kommt dabei die hauseigene IBE-Lösung Quickstart.',
  NewsAlltoursDescription: 'Dank der TravelSandbox®, die von den Düsseldorfer Softwarespezialisten der freshcells systems engineering GmbH entwickelt wurde, gelingt dem Reiseanbieter alltours der Wechsel zur Angebots-Quelle Peakwork Player Hub. Der laufende Betrieb wurde durch den Wechsel nicht unterbrochen, ein Austausch der Online-Plattform war nicht erforderlich. Die TravelSandbox® stellt unter Beweis, dass die Abhängigkeit von externen Datenquellen keine Notwendigkeit mehr ist.',
  NewsFestoDescription: 'Dusseldorf – After less than six months of design and development, followed by a comprehensive testing phase, the Essling-based, family-owned company Festo SE & Co. KG pushes a highly flexible product information API into operation. The API has been developed by the Dusseldorf-based system engineers at freshcells systems engineering GmbH. It aggregates data from the underlying product information management system and presents it structured and highly performant in a Cloud environment. The API is available both enterprise-wide as well as to business partners.',
  NewsLegacyDescription: 'Düsseldorf, Dezember 2022 - Tom Hülser, Gründer und Inhaber der freshcells systems engineering GmbH, bewertet beim Expertenforum des Digihub Düsseldorf/Rheinland die Schwerfälligkeit von über die Jahre gewachsenen Systemlandschaften, als eines der größten Risiken für etablierte Unternehmen in der Digitalen Transformation.',
  NewsChatGPTDescription: 'freshcells, a leading company in the field of digital solutions, is pleased to announce the integration of ChatGPT into their innovative on-page editing tool freshMS. Customers of the premium version of freshMS now have access to marketing automation features with AI support.',
  NewsItb2023Description: 'Düsseldorf, 23 February 2023 – Over the years, the IT company freshcells systems engineering GmbH has established itself in the field of digital distribution platforms and has become a regular and well-known exhibitor in the Travel-Tech hall at ITB. After two years of the COVID pandemic having made face-to-face meetings impossible, freshcells is happy to be back. And freshcells is not coming empty-handed: the new sales features for TravelSandbox® – freshcells\' in-house developed travel tech solution – with the flagship Quickstart make visiting freshcells at ITB worthwhile.',
  NewsInteractiveModulesDescription: 'Düsseldorf, March 2023 – freshcells systems engineering GmbH presents its new, Interactive freshMS Modules at ITB. freshMS is a content management solution for modern, digital marketing. The new, Interactive freshMS Modules now allow users to display the most suitable offers – based on user behaviour – at all times.',
  NewsAppstoresDescription: 'Düsseldorf, März 2023 - freshcells systems engineering GmbH aus Düsseldorf führt ein neues Modul der TravelSandbox® Quickstart in den Markt ein: ab sofort können Unternehmen, die die leistungsstarke One-Platform-Lösung von freshcells nutzen, ihre Verkaufsplattformen auch als native App betreiben.',
  NewsIberostarDescription: 'March 2023 – Since 1987, HLX Touristik GmbH from the last-minute travel innovator Karlheinz Kögel, has proven that package holidays can be sold successfully online. The technological foundation for all the platforms operated by the Baden-Baden company is provided by the Düsseldorf software company freshcells systems engineering GmbH.',
  NewsNextkraftwerkeDescription: 'freshcells systems engineering hat die bestehende Website-Infrastruktur des Kölner Unternehmen Next Kraftwerke, ein Vorreiter im Bereich virtueller Kraftwerke, auf ein hochmodernes Fundament gesetzt.',
  NewsMarvinDescription: 'Düsseldorf, 03.01.2024  – freshcells gibt eine bedeutende Veränderung in der Führungsebene bekannt. Das Senior Management des Unternehmens wird zu Jahresbeginn 2024  neu strukturiert, Marvin Strenger wird zum Chief Operating Officer (COO) ernannt.',
  NewsAdacDescription: 'Düsseldorf, 28.07.2023 - freshcells, der angesehene Anbieter für touristische Online Vertriebsplattformen, wird für den ADAC Reisevertrieb die Evaluierung, Konzeptentwicklung und Implementierung einer neuen Reiseplattform für adacreisen.de durchführen.',
  SystemModernizationPageDescription: 'Our proven unblocking Vision-2-Reality method solve dependencies and innovation blockades. Get ready to tackle the digital challenges of your business now.',
  SolutionsPageDescription: 'We find solutions for your company \'s digital challenges and remove blockades. From enterprise systems to startup ideas. We simply implement your complex project for you. From consulting, conception & design to project management and holistic software development.',
  hrGeneralistDescription: 'Als HR Generalist/Personalreferent umfasst dein Aufgabengebiet sowohl den gesamten Recruiting-Prozess als auch die Etablierung und Verbesserung sämtlicher HR-relevanter Prozesse.',
  contentManagerDescription: 'Als Content Manager hilfst du uns bei der Erstellung und Verarbeitung von fallen internen und externen Inhalten.',
  fairDescription: 'Information about freshcells at fairs and contact persons',
  xmasDescription: 'We are happy to help Santa and you finally make all data usable and optimize processes through modern, easy-to-use interfaces.',
  seniorUiUxDesignerDescription: 'Als UI/UX Designer bist du für die konzeptionelle Entwicklung komplexer Webapplikationen verantwortlich.',
  AGBDescription: 'ALLGEMEINE GESCHÄFTSBEDINGUNGEN der freshcells systems engineering GmbH'
})
